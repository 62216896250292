<template>
  <div class="settings-page">
    <section>
      <h1 class="section-header"> Inställningar </h1>
      <div class="box box--outline mt-5">
        <div class="d-flex justify-content-between">
          <div class="col-xl-3 col-lg-4 col-sm-4 col-md-4 col-6">
            <div class="box-title mb-5">Milersättning</div>
            <input-field type="number" append-content="kr/mil" v-model.number="fuelCost.diesel" label="Diesel"></input-field>
            <input-field type="number" append-content="kr/mil" class="mt-3" v-model.number="fuelCost.gasoline" label="Bensin"></input-field>
            <input-field type="number" append-content="kr/mil" class="mt-3" v-model.number="fuelCost.electric" label="El"></input-field>
            <input-field type="number" append-content="kr/mil" class="mt-3" v-model.number="fuelCost.hybrid" label="Hybrid"></input-field>
          </div>

          <div class="col-6">
            <div class="box box--outline note-box  pb-4" style="height: auto">
              <h2 class="mt-1">Hur fungerar det?</h2>
              Här kan du som administratör på företaget ställa in förarnas milersättning för samtliga drivmedel. Genom att ange kronor/mil räknas det beloppet gånger körda mil, både för privat och tjänsteresor. Föraren och företagets administratörer hittar totala milersättningen i Fordonsexporten som görs under Översikt.
            </div>
          </div>

        </div>
        <div class="d-flex justify-content-end">
          <app-button class="btn--blue" @click="save">Spara</app-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import settingsHttp from '@/http/settings'
import {errorNotification} from "@/utils/translations";

export default {
  name: 'Settings',
  data() {
    return {
      fuelCost: {
        diesel: 0,
        electric: 0,
        gasoline: 0,
        hybrid: 0
      }
    }
  },
  methods: {
    async save() {
      try {
        await settingsHttp.setFuelCost(this.fuelCost)
        this.$notify.success('Sparat!')
      } catch (_) {
        this.$notify.error(errorNotification)
      }
    }
  },
  async created() {
    try {
      const settings = await settingsHttp.getSettings()
      this.fuelCost = settings.fuelCost
    } catch (_) {}
  }
}
</script>

<style scoped>
.box-title {
  font-weight: 600;
}
.note-box {
  text-align: justify;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-gray);
  background: var(--color-gray-xLight);
}
</style>
