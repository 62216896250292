import axios from "axios";

export default {
  getSettings() {
    return axios.get('/settings/company')
  },
  setFuelCost(cost) {
    return axios.put('/settings/company', {fuelCost: cost})
  }
}
